@import '../../../App.scss';

.registro-form-content {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	.registro-form-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.registro-form-big {
			font-size: 38px;
			text-align: center;
			color: $grey-dark;
			font-weight: $extrabold-weight;
			@media screen and (max-width: 800px) {
				font-size:25px;
			}
			span {
				color: $blue-brand;
				font-weight: $extrabold-weight;
				text-decoration: underline;
				text-decoration-color: $yellow-base;
				text-decoration-thickness: 6px;
				text-underline-offset: 9px;
				text-transform: capitalize;
			}
		}
	}
}

.registro-form-mid {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	margin: 0 50px;
	@media screen and (max-width: 1200px){
		margin: 0 15px;
	}
	@include respond(phone) {
		margin: 0 5px;
	}
	.form-registro-wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media screen and (max-width: 800px){
			gap: 10px;
		}

		height: 100%;
		.form-essential {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			height: 100%;
			flex-wrap: wrap;
			gap: 20px;
			margin-bottom: 15px;

			@media screen and (max-width: 800px){
				gap: 10px;
				margin-bottom: 5px;
			}
			.form-essential-title {
				display: block;
				margin-top: 10px;

				.form-essential-title-top {
					display: flex;
					align-items: center;
					justify-content: left;
					font-size: 20px;
					font-weight: $extrabold-weight;
					color: $grey-dark;
					@media screen and (max-width: 800px){
						font-size: 18px;
					}
					&:before {
						content: ' ';
						display: flex;
						width: 5px;
						height: 20px;
						margin-right: 10px;
						background-color: $yellow-base;
					}
				}
				.form-essential-title-link {
					margin-left: 15px;
					font-size: 14px;
					font-weight: $regular-weight;
					color: $tinted-grey-medium;
				}
			}

			.form-essential-content {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: 20px;
				margin-bottom: 15px;
				@media screen and (max-width: 800px) {
					gap: 10px;
					margin-bottom: 5px;
				}
				.form-container-nome {
					width: 100%;
				}
				.form-container-email {
					width: 100%;
				}

				.form-container-senha {
					width: 45%;
				}
			}
		}

		.form-complementary {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			height: 100%;
			flex-wrap: wrap;
			gap: 10px;
			margin-bottom: 15px;

			@media screen and (max-width: 800px){
				gap: 5px;
				margin-bottom: 0px;
			}
			
			.confeti-container {
				display: flex;
				justify-content: center;
				width: 100%;
				height: 100%;
				pointer-events: none;
				position: absolute;
			}
			.form-complementary-title {
				display: block;
				margin-top: 10px;

				.form-complementary-title-top {
					display: flex;
					align-items: center;
					justify-content: left;
					font-size: 20px;
					font-weight: $extrabold-weight;
					color: $grey-dark;
					&:before {
						content: ' ';
						display: flex;
						width: 5px;
						height: 20px;
						margin-right: 10px;
						background-color: $blue-medium;
					}
				}
				.form-complementary-title-link {
					margin-left: 15px;
					font-size: 14px;
					font-weight: $regular-weight;
					color: $tinted-grey-medium;
				}
			}

			.form-complementary-content {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: 20px;
				margin-bottom: 10px;
				@media screen and (max-width: 800px){
					gap: 10px;
					margin-bottom: 5px;
				}
				@include respond(phone) {
					gap: 5px;
					margin-bottom: 5px;
				}

				.form-complementary-primary-info{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					gap: 10px;
				}

				.form-complementary-secondary-info{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					gap: 10px;
				}

				.form-complementary-tertiary-info{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					gap: 10px;
				}

				.form-container-cpf,
				.form-container-nascimento {
					width: auto;
				}
				.form-container-telefone,
				.form-container-cep,
				.form-container-estado {
					width: auto;
				}

				.form-container-cidade,
				.form-container-bairro {
					width: auto;
					@include respond(phone) {
						width: auto;
					}
				}

				.form-rua-info {
					display: flex;
					justify-content: center;
				}

				.form-container-rua {
					width: 100%;
					@include respond(phone) {
						width: 100%;
					}
				}
				@include respond(phone) {
					.form-container-bairro {
						width: auto;
					}
					.form-container-estado {
						width: auto;
					}
					.form-container-cidade {
						width: auto;
					}
				}
				.form-container-número {
					width: auto;
				}
			}

			.form-registro-conclusão {
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.success-icon {
					position: relative;
					img {
						animation: rotation 15s infinite linear;
					}
					span {
						top: 20%;
						left: 17%;
						position: absolute;
						color: $white;
						font-size: 80px;
						font-weight: $bold-weight;
						text-shadow: $txt-shadow;
					}
					@keyframes rotation {
						from {
							transform: rotate(0deg);
						}
						to {
							transform: rotate(359deg);
						}
					}
				}

				.success-txt {
					text-align: center;
					margin-top: 20px;
					font-size: 28px;
					font-weight: $bold-weight;
					color: $grey-brand;
					span {
						text-shadow: $txt-shadow;
						font-size: 56px;
						line-height: 56px;
						color: $green-base;
						text-transform: uppercase;
						font-weight: $extrabold-weight;
					}
				}
			}
		}

		// .form-label {
		// 	margin-left: 5px;
		// 	margin-bottom: 20px;
		// 	height: 6px;
		// 	font-size: 16px;
		// 	font-weight: $semibold-weight;
		// 	color: $grey-brand;
		// }

		// .form-field {
		// 	position: relative;
		// 	margin-top: 2px;
		// 	display: flex;
		// 	border-radius: 5px;
		// 	input {
		// 		background-color: $grey-lightest;
		// 		width: 100%;
		// 		padding: 10px 15px;
		// 		color: $grey-brand;
		// 		border-radius: 5px;
		// 		outline: none;
		// 		transition: 0.2s;
		// 		&:focus {
		// 			box-shadow: $box-shadow;
		// 			background-color: $white;
		// 		}
		// 	}
		// 	select {
		// 		background-color: $grey-lightest;
		// 		width: 100%;
		// 		padding: 10px 15px;
		// 		color: $grey-brand;
		// 		border-radius: 5px;
		// 		outline: none;
		// 		transition: 0.2s;
		// 		&:focus {
		// 			box-shadow: $box-shadow;
		// 			background-color: $white;
		// 		}
		// 		option {
		// 			font-size: 16px;
		// 			font-weight: $regular-weight;
		// 			color: $grey-brand;
		// 		}
		// 	}
		// 	button {
		// 		position: absolute;
		// 		right: 5%;
		// 		top: 18%;
		// 		span {
		// 			font-size: 28px;
		// 			color: $grey-base;
		// 		}
		// 	}
		// }
	}
	.form-btns-container {
		// margin-top: 10px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		button {
			@media screen and (max-width: 1200px){
				width: 120px;
				font-size: 16px;
				height: 40px;
			}
		}
		&:before {
			margin-bottom: 20px;
			content: ' ';
			display: block;
			width: 100%;
			background-color: $grey-light;
			height: 3px;

			@media screen and (max-width: 800px){
				margin-bottom: 10px;
			}
		}
	}
	.pass-forgot {
		margin-top: 20px;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		gap: 2px;
		transition: 0.2s;
		&:hover {
			transform: translateX(2px);
		}
		.pass-forgot-txt {
			font-weight: $regular-weight;
			font-size: 16px;
			color: $tinted-grey-medium;
			text-decoration: underline;
		}
		.pass-forgot-arrow {
			font-size: 24px;
			color: $green-medium;
		}
	}
}

.form-container-termos {
	display: flex;
	align-items: center;
	.filter-option {
		margin-left: 10px;
		float: none;
		color: $grey-brand;

		font-size: 14px;
		font-weight: $semibold-weight;
		text-decoration: none;
		display: block;
		text-align: left;

		.container {
			display: block;
			position: relative;
			padding-left: 35px;

			cursor: pointer;
			font-size: 14px;
			color: $grey-brand;

			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;

				&:checked ~ .checkmark:after {
					display: block;
				}
			}

			.checkmark {
				position: absolute;
				top: 0;
				left: 0;
				height: 22px;
				width: 22px;
				border: 4px solid $blue-brand;

				&:after {
					content: '';
					position: absolute;
					display: none;
					left: 3px;
					top: -9px;
					width: 12px;
					height: 22px;
					border: solid $yellow-base;
					border-width: 0 5px 5px 0;
					-webkit-transform: rotate(40deg);
					-ms-transform: rotate(40deg);
					transform: rotate(40deg);
				}
			}
			.blue-bold {
				font-weight: $semibold-weight;
				color: $blue-base;
				text-transform: capitalize;
			}
		}
	}
}
