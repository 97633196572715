@import '../../App.scss';
 
 .card-container {
 	display: flex;
 	flex-direction: row;
 	padding: 1rem;
 	gap: 1rem;
 
 	@include respond(phone) {
 		flex-direction: column;
 	}
 
 	.card-sou-estudante {
 		display: flex;
 		flex-direction: column;
 		align-items: center;
 		justify-content: center;
 		padding: 1rem;
 		border: 1px solid gainsboro;
 		border-radius: 20px;
 		box-shadow: $box-shadow-sm;
 		min-height: 300px;
 		width: 50%;
 		transition: 0.3s all;
 		cursor: pointer;
 
 		@include respond(phone) {
 			flex-direction: row;
 			min-height: 150px;
 			width: 100%;
 		}
 
 		&:hover {
 			background-color: $grey-light;
 		}
 
 		div {
 			display: flex;
 			flex-direction: column;
 			align-items: center;
 		}
 
 		span {
 			font-size: 3rem;
 			color: white;
 			border-radius: 50%;
 			padding: 0.5rem;
 			background-color: $yellow-base;
 
 			@include respond(phone) {
 				font-size: 2rem;
 			}
 		}
 
 		h1 {
 			font-size: 1.5rem;
 			font-weight: bold;
 			color: $blue-brand;
             text-align: center;
 
 			@include respond(phone) {
 				font-size: 1.3rem;
 			}
 		}
 
 		p {
 			color: $grey-brand;
 			text-align: center;
             margin-top: 0.5rem;
 
 			@include respond(phone) {
 				font-size: 0.9rem;
 			}
 		}
 	}
 }
 
 .compra-title-section-container {
     position: relative;
     padding: 0.625rem 0;
     .title-section {
         margin-left: 0.9rem;
         line-height: 1.5rem !important;
         color: $blue-brand;
     }
 
     &:before {
         left: 0;
         top: 0.625rem;
         position: absolute;
         content: " ";
         height: calc(100% - 1.438rem);
         width: 8px;
         background-color: var(--before-color);
     }
 
     hr {
         border: none;
         height: 3px;
         background-color: $grey-light;
     }
 }
 
 .student-card-list {
     display: flex;
     overflow: auto;
     max-height: 250px;
     justify-content: space-between;
     flex-wrap: wrap;
     gap: 1rem;
 	padding: 0.125rem;
 }
 
 .btn-pesquisar-loading {
 	height: 40px;
 	width: 120px;
 	font-size: 16px;
 	font-weight: $semibold-weight;
 	position: absolute;
 	right: 3px;
 	bottom: 2px;
 	color: $white;
 	background-color: $grey-brand;
 	border-radius: 20px;
 	transition: 0.25s;
 	cursor: not-allowed;
 	img {
 		height: 40px;
 		width: 120px;
 		filter: drop-shadow($drop-shadow);
 	}
 	&:hover {
 		background-color: $grey-dark;
 	}
 	&:focus {
 		outline: none;
 	}
 }