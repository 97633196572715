@import '../../../../App.scss';

.nova-vinculação-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.stepper-steps {
		padding: 0 100px;
		margin-bottom: 20px;
		@include respond(phone){
			padding: 0;
			margin-bottom: 20px;
			margin-left: -20px;
			margin-right: -20px;
		}
	}
	.stepper-content {
		height: 100%;
	}
}
