@import "../../App.scss";

.compra-main-container {
  display: flex;
  justify-self: center;
  gap: 1rem;
  padding: 2rem;
  width: 100%;
  max-width: 1600px;

  @include respond(tab-port) {
    flex-direction: column-reverse;
  }

  @include respond(phone) {
    padding: 4rem 0;
  }

  .step-content-container {
    background-color: white;
    padding: 1.25rem;
    width: 60%;
    border-radius: 10px;
    box-shadow: $box-shadow-sm;

    @include respond(tab-port-lg) {
      width: 55%;
    }

    @include respond(tab-port) {
      width: 100%;
    }

    .checkout-stepper {
      font-size: 1rem !important;
      margin-top: 2.5rem;

      .change-form {
        color: $grey-dark;
        font-weight: $semibold-weight;

        .text-link {
          color: $green-base;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .item-info-container {
    background-color: white;
    width: 40%;
    border-radius: 10px;
    box-shadow: $box-shadow-sm;
    height: fit-content;

    @include respond(tab-port-lg) {
      width: 45%;
    }

    @include respond(tab-port) {
      width: 100%;
    }

    .item-title-container {
      width: 100%;
      background-color: $yellow-base;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0.3rem 0.625rem;

      h5 {
        font-weight: $semibold-weight;
        color: $grey-dark;
      }
    }

    .item-content-container {
      display: flex;
      align-items: flex-strech;
      gap: 0.625rem;
      padding: 0.625rem;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      // max-height: 300px;

      .item-product-image {
        border-radius: 10px;
        width: 15vw;
        object-fit: contain;
        min-width: 100px;
        max-width: 200px;
      }

      .item-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;

        .item-category {
          display: flex;
          align-items: center;
          gap: 0.125rem;

          color: $blue-brand;
          font-weight: $semibold-weight;
          font-size: 0.875rem;
        }

        h4 {
          color: $grey-brand;
        }

        h5 {
          color: $grey-base;
        }

        .item-price {
          text-align: end;
          .price-green {
            font-weight: $semibold-weight;
            color: $green-base;
          }

          .price-blue {
            font-weight: $semibold-weight;
            color: $blue-brand;
          }

          .porcentagem-desconto {
            font-weight: $semibold-weight;
            color: $green-base;
          }
        }
      }
    }
  }

  .cart-view {
    width: 40%;

    @include respond(tab-port-lg) {
      width: 45%;
    }

    @include respond(tab-port) {
      width: 100%;
    }
  }
}
