@import "../../../App.scss";

.aplicar-cupom-div {
  font-size: 20px;
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .aplicar-cupom-lista {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 56%;
    border: 1px solid transparent;
    border-radius: 30px;
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);

    @include respond(phone) {
      justify-content: center;
      width: 100%;
    }

    .cupom-lista-title {
      font-weight: bold;
      color: $grey-dark;
      margin-bottom: 0.5rem;
    }

    .cupom-botao-container {
      display: flex;
      width: 100%;
      padding: 1rem;
      position: relative;
      padding: 0;
      border-radius: 13px;
      border: 3px solid $green-base;

      .cupom-botao {
        color: $green-base;
        border-radius: 5px;
        width: 100%;
        transition: 0.5s all;

        &:hover {
          color: white;
          background-color: $green-base;
        }

        .button-center {
          padding: 0.5rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 0.5rem;
          font-size: 1rem;
          .icon {
            font-size: 28px;
            align-self: center;
          }

          .button-content {
            align-self: center;
          }
        }
      }
    }
  }

  .aplicar-cupom-applied {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    .aplicar-cupom-applied-text {
      text-align: center;
      color: $green-base;
    }

    .remover-cupom-button {
      width: 100%;
      font-family: Arial, Helvetica, sans-serif;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.15rem;
    }

    span {
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: $red;

      span {
        font-size: 16px;
      }
    }
  }

  .aplicar-cupom-form {
    font-weight: bold;
    color: $grey-dark;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: end;
    width: 42%;

    h1 {
      display: flex;
      align-items: center;
      font-size: 1.6rem;

      &::before {
        content: " ";
        display: flex;
        width: 10px;
        height: 35px;
        margin-right: 20px;
        background-color: #ffcc17;
      }
    }

    @include respond(phone) {
      justify-content: center;
      width: 100%;
    }

    .aplicar-cupom-description {
      font-size: 14px;
    }
  }

  @include respond(phone) {
    justify-content: center;
  }

  .aplicar-cupom-control {
    display: flex;
    align-items: center;
    justify-content: center;

    .aplicar-cupom-input {
      width: 100%;
      font-size: 16px;
      padding: 0.5rem;
      border: 1px solid $tinted-grey-medium;

      &:focus {
        outline: none;
      }
    }

    .aplicar-cupom-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $green-base;
      padding: 0.35rem 1rem;

      &:active {
        background-color: $green-medium;
      }

      span {
        color: white;
        font-size: 30px;
      }
    }
  }
}
