@import './variables';

// HELPERS

// VISIBILITY
.hidden {
	visibility: hidden;
	transition: all 0.5s;
}

.shown {
	visibility: visible;
	transition: all 0.5s;
}

// SWAL
.swal2-container {
	z-index: 40000 !important;
}

// REACT-ROUTER-LINK
#link {
	padding: 0;
	margin: 0;
	text-decoration: none;
}

// HEADER SEARCH BOX
.search-box {
	max-width: 100%;
	transition: 0.2s;

	.search-form {
		align-items: center;

		.search-icon {
			display: flex;
			position: absolute;
			bottom: 10px;
			justify-items: center;
			padding-left: 12px;
			pointer-events: none;
			color: $grey-base;
		}

		.search-field {
			display: block;
			height: 45px;
			padding-left: 1rem;
			width: 100%;
			font-size: 14px;
			color: $grey-base;
			outline: none;
			background-color: $grey-lighter;
			border-radius: 22px;
			transition: 0.25s;

			&:focus {
				background-color: $grey-light;
				border: 1px solid $blue-medium;
				// outline: 1px solid $blue-medium;
			}
		}

		.btn-pesquisar {
			height: 40px;
			width: 120px;
			font-size: 16px;
			font-weight: $semibold-weight;
			position: absolute;
			right: 3px;
			bottom: 2px;
			color: $white;
			background-color: $yellow-base;
			border-radius: 20px;
			transition: 0.25s;

			&:hover {
				background-color: $grey-dark;
			}

			&:focus {
				outline: none;
			}
		}

		.btn-adicionar {
			height: 40px;
			width: 120px;
			font-size: 16px;
			font-weight: $semibold-weight;
			position: absolute;
			right: 3px;
			bottom: 2px;
			color: $white;
			background-color: $green-base;
			border-radius: 20px;
			transition: 0.25s;

			&:hover {
				background-color: $grey-dark;
			}

			&:focus {
				outline: none;
			}
		}

		.btn-pesquisar2 {
			height: 100%;
			width: 30px;
			position: absolute;
			right: 3px;
			bottom: 1px;

			&:focus {
				outline: none;
			}
		}
	}
}

// FILTER BTN
.btn-filtro {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	img {
		width: 32px;
		height: auto;
		margin-left: 5px;
		transition: 0.3s;

		&:active {
			transform: rotate(180deg);
		}
	}

	@media screen and (max-width: 1080px) and (min-width: 600px) {
		img {
			width: 28px;
		}
	}

	.btn-filtro-txt {
		font-size: 16px;
		font-weight: $semibold-weight;
		display: flex;
		align-items: center;

		&:before {
			content: ' ';
			width: 5px;
			height: 15px;
			margin-right: 10px;
			background-color: $green-medium;
		}

		@media screen and (max-width: 1080px) and (min-width: 600px) {
			font-size: 14px;
		}
	}
}

// FORMs
.form-label {
	margin-left: 5px;
	// margin-bottom: 15px;
	height: 6px;
	font-size: 14px;
	font-weight: $semibold-weight;
	color: $grey-brand;
}

.form-field {
	position: relative;
	// margin-top: 2px;
	display: flex;
	border-radius: 5px;

	input,
	textarea {
		background-color: $grey-lightest;
		width: 100%;
		padding: 8px 10px;
		color: $grey-brand;
		border-radius: 5px;
		outline: none;
		transition: 0.2s;
		resize: none;

		&:focus {
			box-shadow: $box-shadow;
			border: 1px solid $blue-medium;
			//outline: 1px solid $blue-medium;
			-webkit-box-shadow: $box-shadow;
			background-color: $white;
		}

		&:invalid {
			background-color: $red-light;
		}

		&:disabled {
			background-color: $grey-light;
		}
	}

	select,
	datalist {
		background-color: $grey-lightest;
		width: 100%;
		padding: 12px 10px;
		color: $grey-brand;
		outline: none;
		border-radius: 5px;
		transition: 0.2s;

		&:focus {
			border: 1px solid $blue-medium;
			//outline: 1px solid $blue-medium;
			box-shadow: $box-shadow;
			-webkit-box-shadow: $box-shadow;
			background-color: $white;

			&:invalid {
				background-color: $red-light;
			}

			&:disabled {
				background-color: $grey-light;
			}
		}

		option {
			color: $tinted-grey-medium;
			font-size: 14px;
			font-weight: $semibold-weight;
			text-transform: capitalize;

			&:checked {
				color: $yellow-base;
				text-shadow: $txt-shadow;
			}
		}

		optgroup {
			color: $blue-brand;
			font-size: 16px;
			font-weight: $bold-weight;
			text-transform: uppercase;
		}
	}

	.highlight {
		box-shadow: $box-shadow-highlight;
	}

	button,
	.lock {
		position: absolute;
		right: 5%;
		top: 18%;
		transition: 0.2s;
		font-size: 28px;
		color: $grey-base;
		transition: 0.2s;

		span {
			font-size: 28px;
			color: $grey-base;
			transition: 0.2s;
		}

		&:hover {
			span {
				color: $blue-medium;
			}
		}
	}
}