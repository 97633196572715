@import '../../App.scss';

.step-text{
  font-weight: $bold-weight;
  color: $grey-medium;
}

.step-number{
  font-weight: $semibold-weight;
  color: $white;
  background-color: $grey-medium;
}

.step-selected{
  background-color: $blue-brand;
  border: 3px solid $yellow-base;
}

.step-highlited{
  color: $grey-dark;
}

.step-completed{
  border: 1px solid $yellow-base;
}

.check-mark{
  color: $white;
}

.checkout-stepsDisplay-container{
  margin: 20px 40px 0 40px
}

.resumo-btns-container {
		display: flex;
		justify-content: space-between;
    @include respond(phone){
      flex-direction: column-reverse;
      gap: 10px;
    }
	}