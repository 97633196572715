@import "../../App.scss";

.pagamento-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 1rem;
  @include respond(phone) {
    padding: 0;
    gap: 30px;
    background-color: transparent;
    box-shadow: none;
  }

  #pagamento-loading {
    width: 100%;
    max-height: 300px;
  }
  .pagamento-title {
    display: block;
    text-align: left;
    // margin-bottom: -10px;
    .pagamento-title-top {
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 40px;
      font-weight: $extrabold-weight;
      color: $grey-dark;
      @include respond(phone) {
        font-size: 22px;
        font-weight: $bold-weight;
      }
      &:before {
        content: " ";
        display: flex;
        width: 10px;
        height: 30px;
        margin-right: 20px;
        background-color: $green-medium;
        @include respond(phone) {
          margin-right: 10px;
          width: 7px;
          height: 23px;
        }
      }
    }
    .pagamento-title-link {
      margin-left: 30px;
      font-size: 16px;
      font-weight: $semibold-weight;
      color: $tinted-grey-medium;
      @include respond(phone) {
        margin-left: 20px;
        font-size: 14px;
        font-weight: $regular-weight;
      }
    }
  }

  .pagamento-content {
    // margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include respond(phone) {
      gap: 10px;
    }
    .pagamento-content-btns {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @include respond(phone) {
        justify-content: space-around;
      }
      .filter-text {
        font-size: 16px;
        font-weight: $semibold-weight;
        // margin-left: 5px;
        color: $grey-brand;
        margin-bottom: 10px;
        @include respond(phone) {
          font-size: 14px;
        }
      }
      .pagamento-btn-enabled {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 100%;
      }
      .pagamento-btn-disabled {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 50%;
      }
      .checkbox-btn {
        // margin-left: 10px;
        float: none;
        color: $grey-brand;
        padding: 5px 0px;
        font-size: 14px;
        font-weight: $semibold-weight;
        text-decoration: none;
        display: block;
        text-align: left;

        .checkbox-label {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          // padding-left: 35px;
          cursor: pointer;
          color: $grey-brand;
          img {
            width: 80px;
            margin-top: -10px;
          }
          input {
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark:after {
              display: block;
            }
          }

          // .checkmark {
          // 	background-color: $grey-lighter;
          // 	position: absolute;
          // 	top: 0;
          // 	left: 0;
          // 	height: 28px;
          // 	width: 28px;
          // 	border: 4px solid $blue-brand;

          // 	&:after {
          // 		content: '';
          // 		position: absolute;
          // 		display: none;
          // 		left: 4px;
          // 		top: -9px;
          // 		width: 16px;
          // 		height: 28px;
          // 		border: solid $yellow-base;
          // 		border-width: 0 7px 7px 0;
          // 		-webkit-transform: rotate(40deg);
          // 		-ms-transform: rotate(40deg);
          // 		transform: rotate(40deg);
          // 	}
          // }
        }
      }
    }
  }

  .cart-orçamento {
    flex-basis: 20%;
    // padding-bottom: 20px;
    align-items: flex-end;
    justify-content: flex-end;
    .btns-container {
      margin: auto 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond(phone) {
        margin: auto 10px;
      }
    }
    .orçamento-total {
      margin: auto 20px;
      @include respond(phone) {
        margin: auto 5px;
      }
      .orçamento-total-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0 0;
        @include respond(phone) {
          padding: 10px 0;
        }
        .orçamento-subtotal {
          font-size: 24px;
          font-weight: $bold-weight;
          color: $grey-dark;
          text-transform: uppercase;
          @include respond(phone) {
            font-size: 16px;
            font-weight: $bold-weight;
          }
        }
        .orçamento-número {
          font-size: 24px;
          font-weight: $extrabold-weight;
          color: $green-base;
          @include respond(phone) {
            font-size: 16px;
            font-weight: $bold-weight;
          }
        }
      }

      .cart-orçamento-detalhes {
        margin-top: 0px;
        display: block;
        text-align: right;
        font-size: 18px;
        line-height: 20px;
        color: $grey-brand;
        font-weight: $regular-weight;
        @include respond(phone) {
          margin-top: -15px;
          font-size: 14px;
        }
        .blue-bold {
          color: $blue-brand;
          font-weight: $bold-weight;
        }
      }

      &:after {
        margin-top: 20px;
        content: " ";
        display: block;
        width: 100%;
        background-color: $grey-light;
        height: 2px;
        @include respond(phone) {
          margin-top: 10px;
        }
      }
      &:before {
        margin-bottom: 20px;
        content: " ";
        display: block;
        width: 100%;
        background-color: $grey-light;
        height: 2px;
        @include respond(phone) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .pagamento-btns-container {
    display: flex;
    justify-content: space-between;
  }
}

.pagamento-itens-resumo {
  margin: auto 20px;
  @include respond(phone) {
    margin: auto 5px;
  }
  &:before {
    margin-bottom: 30px;
    content: " ";
    display: block;
    width: 100%;
    background-color: $grey-light;
    height: 2px;
    @include respond(phone) {
      margin-bottom: 10px;
    }
  }
  .modalidade-title {
    margin-bottom: 30px;
    display: block;
    text-align: left;
    display: block;
    text-align: left;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: $extrabold-weight;
    color: $grey-dark;
    @include respond(phone) {
      font-size: 16px;
      font-weight: $bold-weight;
      margin-bottom: 15px;
    }
    &:after {
      content: " ";
      display: flex;
      width: 150px;
      height: 5px;
      margin-top: 5px;
      margin-right: 20px;
      background-color: $yellow-base;
      @include respond(phone) {
        width: 80px;
        margin-top: 2px;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include respond(phone) {
      gap: 10px;
    }
    li {
      display: flex;
      align-items: left;
      justify-content: space-between;
      .resumo-itens-left {
        display: flex;
        align-items: center;
        gap: 20px;
        @include respond(phone) {
          gap: 2px;
        }
      }
      .resumo-li-quantidade {
        font-size: 18px;
        color: $blue-base;
        font-weight: $bold-weight;
        @include respond(phone) {
          font-size: 12px;
          font-weight: $semibold-weight;
        }
      }
      .resumo-li-nome {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: $grey-brand;
        font-weight: $semibold-weight;
        text-transform: capitalize;
        .resumo-li-estudante {
          font-size: 14px;
          color: $blue-brand;
          font-weight: $semibold-weight;
          display: flex;
          align-items: center;
          @include respond(phone) {
            font-size: 10px;
          }
          .iconVinc {
            font-size: 18px;
            color: $yellow-base;
            transform: rotate(45deg);
          }
        }
        @include respond(phone) {
          font-size: 12px;
          font-weight: $regular-weight;
        }
      }
      .resumo-li-preço {
        font-size: 22px;
        color: $green-base;
        font-weight: $extrabold-weight;
        @include respond(phone) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 14px;
          line-height: 14px;
          font-weight: $semibold-weight;
        }
        .preco-cortado {
          text-decoration: line-through;
          text-decoration-color: $grey-brand;
          text-decoration-thickness: 1.5px;
          color: $green-light;
          font-size: 16px;
          font-weight: $bold-weight;
          @include respond(phone) {
            font-size: 12px;
          }
        }
        .preco-desconto {
          font-size: 18px;
          font-weight: $bold-weight;
          color: $green-light;
          margin-left: 5px;
          text-align: center;
          @include respond(phone) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
