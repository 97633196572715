@import '../../../App.scss';

.modal-vinculo-wrapper {
	position: relative;
	height: auto;
	width: 800px;
	background: $linear-gradient;
	box-shadow: $box-shadow;
	border-radius: 10px;
	overflow: hidden;
	@media screen and (max-width: 800px) {
		min-height: 80vh;
		width: 100vw;
	}
	@include respond(phone) {
		height: 100vh;
		width: 100vw;
		border-radius: 0;
	}
	@media screen and (min-height: 600px){
		height: 100%;
	}
	.modal-vinculo-container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;

		.modal-vinculo-header {
			flex-basis: fit-content;
			display: flex;
			flex-direction: row;
			align-items: left;
			justify-content: space-between;
			padding: 5px 20px;
			gap: 30px;
			background-color: $blue-bg;
			@media screen and (max-width: 800px){
				padding: 5px 15px;
				gap:10px
			}
			@include respond(phone) {
				flex-direction: column;
				gap: 10px;
			}

			.modal-vinculo-title {
				flex-basis: fit-content;
				display: flex;
				flex-direction: column;
				.modal-vinculo-title-big {
					display: flex;
					align-items: center;
					justify-content: space-between;
					h2 {
						display: flex;
						align-items: center;
						font-size: 22px;
						font-weight: $extrabold-weight;
						color: $white;
						@include respond(phone) {
							margin-top: 5px;
							font-size: 16px;
							font-weight: $bold-weight;
						}
						&:before {
							content: ' ';
							display: flex;
							width: 7px;
							height: 24px;
							margin-right: 10px;
							background-color: $green-base;
						}
					}
				}
				p {
					margin-left: 20px;
					font-size: 14px;
					font-weight: $semibold-weight;
					color: $tinted-grey-medium;
					@include respond(phone) {
						margin-left: 16px;
						font-size: 12px;
						font-weight: $regular-weight;
					}
				}
			}

			.modal-cart-item {
				flex-basis: auto;
				margin-right: 10%;
				@include respond(phone) {
					margin-right: 0;
				}
				.modal-cart-item-container {
					display: flex;
					flex-direction: row;
					gap: 10px;
					min-height: 80px;
					width: 350px;
					background-color: $white;
					box-shadow: $box-shadow;
					border-radius: 10px;
					padding: 5px;
					@include respond(phone) {
						width: 100%;
					}

					.modal-cart-item-img-container {
						flex-basis: 25%;
						width: 100%;
						height: 100%;
						overflow: hidden;
						object-fit: cover;
						border-radius: 3px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.modal-cart-item-info-container {
						flex-basis: 75%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						height: 100%;

						.modal-cart-item-info-top {
							.modal-cart-item-name {
								font-size: 14px;
								color: $grey-dark;
								font-weight: $semibold-weight;
								text-transform: capitalize;
							}

							.unit-name {
								font-size: 12px;
								font-weight: $bold-weight;
							}
						}
						.modal-cart-item-info-mid {
							display: flex;
							flex-direction: column;
							gap: 0;
							margin: 0;
							@include respond(phone) {
								gap: 0;
								margin: 0;
							}
							.iconCateg {
								color: $blue-base;
								font-size: 16px;
								margin-right: 2px;
							}
							.iconVinc {
								color: $yellow-base;
								font-size: 16px;
								margin-right: 2px;
								transform: rotate(45deg);
							}
							.categ-txt {
								margin-left: 3px;
								font-weight: $regular-weight;
							}
							.vinc-txt {
								margin-left: 3px;
								font-weight: $bold-weight;
								color: $blue-brand;
							}
							.modal-cart-item-categoria {
								display: flex;
								align-items: center;
								font-size: 12px;
								font-weight: $semibold-weight;
								color: $tinted-grey-base;
							}

							.modal-cart-item-estudante {
								display: flex;
								align-items: center;
								font-size: 12px;
								font-weight: $semibold-weight;
								color: $blue-brand;
								text-transform: capitalize;
							}
							&:after {
								margin-top: 5px;
								content: ' ';
								display: block;
								width: 100%;
								background-color: $grey-light;
								height: 2px;
							}
						}
						.modal-cart-item-info-bot {
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex-direction: row;

							.modal-cart-item-price {
								display: flex;
								align-items: baseline;
								flex-direction: column;

								.price-green {
									font-size: 14px;
									line-height: 18px;
									color: $green-base;
									font-weight: $extrabold-weight;
								}
								.price-blue {
									font-weight: $bold-weight;
									color: $blue-brand;
									font-size: 12px;
									line-height: 12px;
								}

								.porcentagem-desconto {
									margin-left: 5px;
									font-size: 12px;
									font-weight: $semibold-weight;
									color: $green-light;
								}

								h4 {
									font-size: 12px;
									line-height: 12px;

									color: $grey-base;
									font-weight: $regular-weight;
								}
								h3 {
									font-size: 10px;
									line-height: 10px;
									color: $grey-base;
									font-weight: $regular-weight;
								}
							}
							.modal-cart-item-quantity {
								font-size: 18px;
								font-weight: $semibold-weight;
								color: $blue-brand;
							}
						}
					}
				}
			}

			.close-btn {
				position: absolute;
				right: 5px;
				top: 5px;
				span {
					font-size: 44px;
					font-weight: $extrabold-weight;
					color: $red;
					text-shadow: $txt-shadow;
					transition: 0.2s;
					@include respond(phone) {
						font-size: 32px;
					}
					&:hover {
						transform: translateY(-1.5px);
						text-shadow: $txt-shadow-lg;
					}
				}
			}
		}

		.modal-vinculo-tabnav {
			position: relative;
			flex-basis: fit-content;
			&:after {
				content: ' ';
				display: flex;
				margin-top: -6px;
				width: 100%;
				background-color: $grey-light;
				height: 6px;
			}

			.tabnav-bar {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 60px;
				margin-bottom: -6px;
				margin-right: 1px;
				margin-left: 1px;
				@include respond(phone) {
					height: 50px;
				}
				a {
					margin: auto 10px;
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;
					height: 100%;
					transition: 0.3s;
					@include respond(phone) {
						gap: 5px;
					}
					.tabnav-icon {
						height: 100%;
						width: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						span {
							color: $blue-base;
							font-size: 34px;
							@include respond(phone) {
								font-size: 22px;
							}
						}
						.clip {
							color: $yellow-base;
							transform: rotate(-45deg);
						}
					}
					h2 {
						white-space: nowrap;
						color: $grey-brand;
						font-size: 18px;
						font-weight: $bold-weight;
						@include respond(phone) {
							font-size: 14px;
						}
					}
				}
				.list-anchor-active {
					opacity: 1;
					border-bottom: 6px solid $green-base;
					z-index: 10000;
				}
				.list-anchor-inactive {
					opacity: 0.3;
					border-bottom: 6px solid transparent;
				}
			}
			.tabnav-fade-right {
				position: absolute;
				background: linear-gradient(-90deg, $white 50%, transparent);
				content: ' ';
				width: 20px;
				height: 70px;
				right: 0;
				top: 0;
				z-index: 10001;
			}
			.tabnav-fade-left {
				position: absolute;
				background: linear-gradient(90deg, $white 50%, transparent);
				content: ' ';
				width: 20px;
				height: 70px;
				left: -1px;
				top: 0;
				z-index: 10001;
			}
		}
		.modal-vinculo-content {
			height: 475px;
			background: $linear-gradient;
			padding: 0px 10px 10px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			@media screen and (max-width: 1000px){
				height: 430px;
			}
			@include respond(phone) {
				height: 100%;
			}
			.modal-vinculo-content-tab1 {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				@media screen and (max-width:1000px) {
					height: 90%;
				}

				@media screen and (max-width: 800px) {
					height: 80%;
				}

				@include respond(phone) {
					height: 61%;
				}
				@include respond(phone-xs){
					height: 59%;
				}
				.modal-vinculo-tab1-search {
					margin: 20px 100px;
					@include respond(phone) {
						margin: 10px 10px;
					}
					.search-field {
						display: block;
						height: 45px;
						padding-left: 40px;
						width: 100%;
						font-size: 14px;
						color: $grey-base;
						outline: none;
						background-color: $grey-light;
						border-radius: 22px;
						transition: 0.25s;
						&:focus {
							box-shadow: $box-shadow-circle;
							-webkit-box-shadow: $box-shadow-circle;
							border: 1px solid $blue-medium;
							//outline: 1px solid $blue-medium;
							background-color: $white;
						}
					}
					.btn-pesquisar {
						height: 40px;
						width: 120px;
						font-size: 16px;
						font-weight: $semibold-weight;
						position: absolute;
						right: 3px;
						bottom: 2px;
						color: $white;
						background-color: $green-medium;
						border-radius: 20px;
						transition: 0.25s;
						&:hover {
							background-color: $grey-dark;
						}
						&:focus {
							outline: none;
						}
					}
					.btn-pesquisar-loading {
						height: 40px;
						width: 120px;
						font-size: 16px;
						font-weight: $semibold-weight;
						position: absolute;
						right: 3px;
						bottom: 2px;
						color: $white;
						background-color: $grey-brand;
						border-radius: 20px;
						transition: 0.25s;
						cursor: not-allowed;
						img {
							height: 40px;
							width: 120px;
							filter: drop-shadow($drop-shadow);
						}
						&:hover {
							background-color: $grey-dark;
						}
						&:focus {
							outline: none;
						}
					}
				}
				.modal-vinculo-tab1-lista {
					padding: 20px;
					height: 80%;
					min-height: 180px;
					overflow-y: scroll;
					overflow-x: hidden;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					flex-wrap: wrap;
					@media screen and (max-width: 700px) {
						min-height: 170px;
						display: block;
					}
					@include respond(phone) {
						min-height: 140px;
						padding: 5px;
					}
					@include respond(phone-xs){
						min-height: 140px;
					}
					.big-loading-bars {
						width: 100%;
						height: 200px;
						// margin-top: -50px;
					}
					.vinculo-lista-item {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						column-gap: 10px;
						row-gap: 20px;

						@media screen and (max-width: 800px){
							row-gap: 5px;
						}
					}
				}
				.modal-vinculo-tab1-btns {
					padding: 0 15px 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;

					@include respond(phone) {
						padding: 0px;
						display: flex;
					}
					&:before {
						margin-bottom: 20px;
						content: ' ';
						width: 100%;
						background-color: $grey-light;
						height: 3px;
						@media screen and (max-width: 1200px){
							margin-bottom: 15px;
						}
						@include respond(phone) {
							display: block;
						}
					}
				}
			}
			.modal-vinculo-content-tab2 {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
	}
}

.card-container {
	display: flex;
	flex-direction: row;
	padding: 3rem;
	gap: 1rem;

	@include respond(phone) {
		flex-direction: column;
	}

	.card-sou-estudante {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		border: 1px solid gainsboro;
		border-radius: 20px;
		box-shadow: $box-shadow-sm;
		min-height: 200px;
		width: 50%;
		transition: 0.3s all;
		cursor: pointer;

		@include respond(phone) {
			flex-direction: row;
			min-height: auto;
			width: 100%;
		}

		&:hover {
			background-color: $grey-light;
		}

		div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		span {
			font-size: 3rem;
			color: white;
			border-radius: 50%;
			padding: 0.5rem;
			background-color: $yellow-base;

			@include respond(phone) {
				font-size: 2rem;
			}
		}

		h1 {
			font-size: 1.5rem;
			font-weight: bold;
			color: $blue-brand;

			@include respond(phone) {
				font-size: 1.3rem;
			}
		}

		p {
			color: $grey-brand;
			text-align: center;

			@include respond(phone) {
				font-size: 0.9rem;
			}
		}
	}
}