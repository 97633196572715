@import '../../../App.scss';
 
 .title-with-underline {
     display: inline-block;
     position: relative;
     align-self: flex-start;
 
     &:after {
         content: ' ';
         height: 5px;
         width: 100%;
         background-color: $yellow-base;
         display: flex;
         position: absolute;
     }
 }