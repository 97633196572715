// TYPOGRAPHY
@font-face {
	font-family: 'TTNorms';
	src: url(../fonts/TTNorms-Regular.otf) format('opentype');
	font-weight: normal;
}

@font-face {
	font-family: 'TTNorms';
	src: url(../fonts/TTNorms-Medium.otf) format('opentype');
	font-weight: 500;
}

@font-face {
	font-family: 'TTNorms';
	src: url(../fonts/TTNorms-Bold.otf) format('opentype');
	font-weight: 600;
}

@font-face {
	font-family: 'TTNorms';
	src: url(../fonts/TTNorms-Bold.otf) format('opentype');
	font-weight: bold;
}

a {
	text-decoration: none;
	color: black;
}