.images-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.images-container > img {
  width: 3.5rem;
  height: auto;
}
