@import '../../../App.scss';

.account-modal-wrapper {
	overflow: hidden;
	min-height: 400px;
	width: 40vw;
	padding: 20px;
	background-color: $white;
	box-shadow: $box-shadow;
	border-radius: 10px;
	// background-image: url(../../../assets/Bgs/bg-login.svg);
	// background-position: -30px 110px;
	// background-repeat: no-repeat;
	// background-size: contain;
	position: relative;

	@media screen and (max-width: 900px){
		width: 50vw;
	}

	@include respond(phone) {
		height: auto;
		width: auto;
		border-radius: 0;
		// padding: 30px 10px;
	}

	// @include respond(phone-xs) {
	// 	height: 100%;
	// }

	.account-loading-bars {
		width: 50%;
		min-height: 600px;
		display: block;
		margin: auto;
		@include respond(phone){
			min-height: 100vh;
			width: 100vw;
		}
	}
	.account-modal-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		.close-btn {
			position: absolute;
			right: 5px;
			top: 5px;
			span {
				font-size: 44px;
				font-weight: $extrabold-weight;
				color: $red;
				text-shadow: $txt-shadow;
				transition: 0.2s;
				@include respond(phone) {
					font-size: 32px;
				}
				&:hover {
					transform: translateY(-1.5px);
					text-shadow: $txt-shadow-lg;
				}
			}
		}
		.login-top {
			margin-bottom: 25px;
			@media screen and (max-width: 800px){
				margin-bottom: 10px;
			}
			@include respond(phone) {
				margin-bottom: 10px;
			}
			.modal-vinculo-navbar-ul {
				display: flex;
				justify-content: space-around;
				flex-direction: row;
				.list-anchor {
					font-size: 18px;
					font-weight: $bold-weight;
					transition: 0.2s;
					text-transform: uppercase;
					width: 120px;
				}
				.list-anchor-active-yellow {
					color: $blue-brand;
					display: flex;
					flex-direction: column;
					align-items: center;
					&:after {
						content: ' ';
						margin-top: 5px;
						display: flex;
						width: 100%;
						background-color: $yellow-medium;
						height: 6px;
					}
				}
				.list-anchor-active-green {
					color: $blue-brand;
					display: flex;
					flex-direction: column;
					align-items: center;
					&:after {
						content: ' ';
						margin-top: 5px;
						display: flex;
						width: 100%;
						background-color: $green-light;
						height: 6px;
					}
				}
				.list-anchor-inactive {
					color: $grey-medium;
					display: flex;
					flex-direction: column;
					align-items: center;
					&:hover {
						opacity: 1;
					}
				}
			}
		}
		.modal-vinculo-estudantes {
			height: 100%;
			#link1,
			#link2 {
				height: 100%;
			}
		}
		.login-form-bot {
			padding: 0 30px;
			&:before {
				margin-bottom: 15px;
				content: ' ';
				display: block;
				width: 100%;
				background-color: $grey-light;
				height: 2px;
				@media screen and (max-width: 800px){
					margin-bottom: 10px;
				}
			}
			.login-form-bot-content {
				margin: 0 -30px;
				display: flex;
				gap: 20px;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				@media screen and (max-width: '800px'){
					gap: 15px
				}
				.bot-content-top {
					margin-top: 10px;
					font-size: 20px;
					color: $grey-base;
					font-weight: $bold-weight;
					@media screen and (max-width: 800px){
						margin-top: 0;
					}
					span {
						text-decoration: underline;
						text-decoration-color: $yellow-base;
						text-decoration-thickness: 4px;
						text-underline-offset: 10px;
					}
				}
				.login-form-list {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					ul {
						display: flex;
						gap: 20px;
						font-size: 14px;
						font-weight: $regular-weight;
						li {
							display: flex;
							align-items: center;
							gap: 5px;
							color: $tinted-grey-base;
							span {
								color: $yellow-base;
								font-size: 20px;
							}
							.blue {
								color: $blue-medium;
							}
							.green {
								color: $green-medium;
							}
						}
					}
				}
				.bot-content-btns {
					margin-top: 10px;
					width: 70%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					button {
						transition: 0.2s;
						opacity: 0.9;
						img {
							height: 64px;
							width: 64px;
						}
						&:hover {
							transform: translateY(-3px);
							opacity: 1;
						}
					}
					.apple {
						margin-bottom: 13px;
						img {
							height: 68px;
							width: 68px;
						}
					}
				}
			}
		}
	}
}
