// VARIABLES

// SHADOWS
$box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
$box-shadow-sm: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
$box-shadow-xsm: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
$box-shadow-lg: 0px 0px 32px 0px rgba(0, 0, 0, 0.3);
$box-shadow-circle: 0px 0px 15px 4px rgba(0, 0, 0, 0.1);
$box-shadow-nav: 0px 0px 32px 0px rgba(0, 0, 0, 0.1);
$box-shadow-nav-bot: 0px -10px 32px 0px rgba(0, 0, 0, 0.1);
$box-shadow-nav-top: 0px 10px 32px 0px rgba(0, 0, 0, 0.1);
$box-shadow-highlight: 0px 0px 16px 4px rgb(12, 36, 97, 0.5);
$txt-shadow-lg: 2px 2px 8px rgba(0, 0, 0, 0.4);
$txt-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
$drop-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
$drop-shadow-sm: 2px 2px 2px rgba(12, 36, 97, 0.2);

// FONT WEIGHTS
$regular-weight: 400;
$semibold-weight: 600;
$bold-weight: 700;
$extrabold-weight: 800;

// PRIMARY COLORS
$blue-brand: #1e3799;
$blue-dark: #14387F;
$grey-brand: #5b6772;
$white: #fff;
$blue-base: #10244c;
$blue-medium: #3679D9;
$blue-light: #B8DEF2;
$yellow-base: #fdd636;
$yellow-medium: #fdd636;
$yellow-light: #ffe188;
$green-dark: #166939;
$green-base: #30C884;
$green-medium: #30C884;
$green-light: #6dde9d;

// SECONDARY COLORS
$blue-bg: #0c2461;
$grey-dark: #3e464e;
$grey-base: #74818e;
$grey-medium: #bdc7d0;
$grey-light: #e4ebf1;
$grey-lighter: #f5f5f5;
$grey-lightest: #f8f8f8;
$tinted-grey-dark: #4d7375;
$tinted-grey-base: #659193;
$tinted-grey-medium: #83a8aa;
$red: #ff6363;
$red-light: #ffb5b5;

// GRADIENTS
$linear-gradient: linear-gradient(
	180deg,
	rgba(255, 255, 255, 1) 20%,
	rgba(246, 246, 246, 1) 50%,
	rgba(238, 238, 238, 1) 100%
);
